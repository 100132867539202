import * as React from "react"
import { ApolloError } from "@apollo/client"
import { TokenRefreshMutationFn } from "@digits-graphql/frontend/graphql-public"
import useSession from "@digits-shared/hooks/useSession"
import BearerRefreshManager from "@digits-shared/session/BearerRefreshManager"
import { GraphQLError } from "graphql"
import FrontendSession from "src/frontend/session"

export default function useSessionTokenRefresh(tokenRefreshMutation: TokenRefreshMutationFn) {
  const session = useSession<FrontendSession>()
  const activeLegalEntityId = session.lastKnownLegalEntityId
  const activeRefreshMutationRef = React.useRef(false)

  const onFetch = React.useCallback(
    (skipNotifyTokenUpdate = false) => {
      // Don't attempt to fetch if the user is logged out
      if (!session.hasUserData) {
        // Need to clear the refresh active flag in the session and notify listeners
        session.bearerRefreshSkipped()
        return
      }
      // Don't attempt to fetch if another request is active
      if (activeRefreshMutationRef.current) {
        // Flag clearing and notification is handled by the in-flight request
        return
      }

      activeRefreshMutationRef.current = true
      tokenRefreshMutation({
        variables: {
          activeLegalEntityId,
        },
        context: {
          publicAPI: true,
          skipNotifyTokenUpdate,
        },
      })
        .then((results) => {
          if (results.errors) throw new ApolloError({ graphQLErrors: results.errors })
          if (!results.data) {
            throw new ApolloError({
              graphQLErrors: [new GraphQLError("No data returned from token refresh mutation")],
            })
          }

          activeRefreshMutationRef.current = false
          // do not reset bearer token if there is no user data (logged-out user)
          // this hook can be called right after the user has logged out which will log them back in.
          if (!session.hasUserData) return
          return session.onBearerChange(results.data.tokenRefresh, skipNotifyTokenUpdate)
        })
        .catch((error: ApolloError) => {
          TrackJS?.track(error)
          activeRefreshMutationRef.current = false
          error.graphQLErrors?.forEach((e) => {
            session.bearerFetchError(e)
          })
        })
    },
    [activeLegalEntityId, session, tokenRefreshMutation]
  )

  // Needs to be a layout effect so that the listener is registered immediately/synchronously.
  // Otherwise, it's possible for the listener to be registered _after_ an Apollo request is
  // queued. That pending request triggers a blocking token refresh, but this handler can't
  // react if the listener is not yet registered. The queued calls are then deadlocked.
  React.useLayoutEffect(() => {
    session.on(BearerRefreshManager.TOKEN_BLOCKING_STARTED_EVENT_NAME, onFetch)
    session.on(BearerRefreshManager.TOKEN_NON_BLOCKING_STARTED_EVENT_NAME, onFetch)

    return () => {
      session.off(BearerRefreshManager.TOKEN_BLOCKING_STARTED_EVENT_NAME, onFetch)
      session.off(BearerRefreshManager.TOKEN_NON_BLOCKING_STARTED_EVENT_NAME, onFetch)
    }
  }, [session, onFetch])

  React.useEffect(() => {
    session.blockingBearerRefresh()
  }, [activeLegalEntityId, session])
}

import { SessionFieldsFragment } from "@digits-graphql/frontend/graphql-public"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import envHelper from "@digits-shared/helpers/envHelper"
import EventEmitter from "@digits-shared/helpers/events/eventEmitter"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { storage as globalStorage, StorageFacade } from "@digits-shared/helpers/storage/storage"
import BearerRefreshManager from "@digits-shared/session/BearerRefreshManager"
import DoppelgangerAccessLevel, { AccessLevel } from "@digits-shared/session/DGAccessLevel"
import { jwtParse } from "@digits-shared/session/jwt/jwtParser"
import { JWTSession } from "@digits-shared/session/jwt/jwtSession"
import { ObjectTokens } from "@digits-shared/session/ObjectTokens"
import SessionUser from "@digits-shared/session/User"
import { GraphQLFormattedError } from "graphql"
import { jwtDecode } from "jwt-decode"
import moment from "moment"

// Do not make employments required (some endpoints like registrations do not include it)
export type GraphqlSession = SessionFieldsFragment

export const DIGITS_EMPLOYEE_OVERRIDE = "digits-employee-override"

/**
 * Use for describing the reason for a session being logged out.
 * message @type {string} message to be used in ui describing the logout
 * type @type {Type} enum to key off
 * from @type {{ pathname: string }} Optional url the user was previous to route back to
 * if they log back in
 */

export interface SessionLogoutState {
  code: GRPCErrorCode
  message?: string
  from?: { pathname: string }
}

/**
 * Controls the session data about the user and authenticated state.
 *
 * This object will only persist the JWT token we receive from the server.
 * Any data added will only be in memory and does not persist past a refresh.
 * Only data that is encoded on the JWT token will survive page reloads.
 */
export default class Session<JWT extends JWTSession = JWTSession> extends EventEmitter {
  static JWT_STORAGE_NAMESPACE = "session.jwt"
  static GLOBAL_STORAGE_NAMESPACE = "global.preferences"
  static PREFERENCES_STORAGE_NAMESPACE = "session.preferences"

  static CREATE_EVENT_NAMESPACE = "session:create"
  static UPDATE_EVENT_NAMESPACE = "session:update"
  static DELETE_EVENT_NAMESPACE = "session:delete"
  static MUTATION_VERSION_NAMESPACE = "session:mutationVersion"

  static JWT_CREATE_EVENT_NAME = `${Session.CREATE_EVENT_NAMESPACE}:jwt`
  static JWT_UPDATE_EVENT_NAME = `${Session.UPDATE_EVENT_NAMESPACE}:jwt`
  static JWT_DELETE_EVENT_NAME = `${Session.DELETE_EVENT_NAMESPACE}:jwt`
  static JWT_MUTATION_VERSION_NAME = `${Session.MUTATION_VERSION_NAMESPACE}:jwt`

  static PAGE_REFRESH_REQUIRED_EVENT_NAME = "session:pageRefreshRequired"

  user: SessionUser

  doppelganger?: DoppelgangerAccessLevel

  protected encodedSignedJWT?: string

  protected decodedSignedJWT?: JWT

  protected decodedUnsignedJWT?: JWT

  protected bearerFetcher: BearerRefreshManager

  protected isBearerRefreshActive = false

  protected storage: StorageFacade

  // Copied from Pay
  private objectTokens: ObjectTokens

  constructor(storage: StorageFacade = globalStorage) {
    super()

    this.storage = storage

    this.bearerFetcher = new BearerRefreshManager(this)

    this.decodedUnsignedJWT = this.encodedUnsignedJWT
      ? jwtDecode<JWT>(this.encodedUnsignedJWT)
      : undefined
    this.decodeSession()

    this.objectTokens = new ObjectTokens(this.storage)
  }

  /**
   * Checks if the user is authenticated.
   *
   * Simply checks if we have an unsigned JWT token set.
   * This is enough to allow access to begin viewing authenticated pages.
   * While it's not guarantee the user is logged in, we'll log the user out
   * if the server returns a 400.
   * @return {boolean} If the user has an unsigned jwt
   */
  get hasUserData() {
    return !!this.encodedUnsignedJWT
  }

  get bearerRefreshActive() {
    return this.isBearerRefreshActive
  }

  //
  //  BEARER
  //

  /**
   * Checks if the session is for a Digits employee.
   *
   * Stopgap until we have a better way of turning off features.
   * TODO: Remove once we have feature switching built
   * @return {boolean} If the session is for a Digits employee
   */
  get isDigitsEmployee() {
    // Development Override
    const override = this.getUserPreference(DIGITS_EMPLOYEE_OVERRIDE)
    if (!envHelper.isProduction() && override !== undefined) {
      return override
    }

    const isDigitsEmployee = !!this.user?.emailAddress?.match(/@digits.com$/)
    return isDigitsEmployee || !!this.doppelganger?.hasFullAccess
  }

  /**
   * Checks if the session is a doppelganger.
   *
   * @return {boolean} if the session is a doppelganger session
   */
  get isDoppelganger() {
    return !!this.doppelganger
  }

  /**
   * Puts a Doppelganger session in a state that will turn off any Digits Employee only features
   * normally available for Doppelgangers. Typically used for debugging.
   *
   */
  set accessLevel(level: AccessLevel) {
    // Don't allow non-doppelgangers to change it
    if (!this.doppelganger) return
    this.doppelganger.level = level

    this.emit(Session.JWT_UPDATE_EVENT_NAME)
  }

  /*
    DOPPELGANGER
  */
  get doppelgangerPermit() {
    return this.decodedUnsignedJWT?.user?.dg
  }

  /**
   * Getter for JWT. Just a wrapper for retrieving from
   * local this.storage. This is meant to be private and for internal
   * class use only. Use `bearer` to get JWT token.
   */
  protected get encodedUnsignedJWT(): string | null {
    const encoded = this.storage.local.getItem(Session.JWT_STORAGE_NAMESPACE)
    const length = encoded?.split(".").length

    switch (length) {
      case 2:
        return encoded
      case 3:
        if (encoded) {
          const decoded = jwtDecode<JWT>(encoded)
          TrackJS?.track(
            `ignoring signed JWT from localstorage (causes user logout). JWT issued at ${moment
              .unix(decoded.iat)
              .utc()
              .toDate()}. DG permit: "${decoded.user?.dg}"`
          )
        } else {
          TrackJS?.track("encoded JWT is null but considered signed")
        }
        return null
      default:
        return null
    }
  }

  /**
   * Handles local storage change events to determine if they affected the stored
   * JWT token. Only propagate a removal of the unsigned JWT from local storage so we
   * automatically sign out all tabs at the same time.
   *
   * Intended to be registered as an event listener callback outside this class.
   *
   * @param e {StorageEvent} event from a storage listener
   */
  handleLocalStorageUpdated = (e: StorageEvent) => {
    if (e.key !== Session.JWT_STORAGE_NAMESPACE) return

    // logout synchronization
    const storageEncodedUnsignedJWT = this.encodedUnsignedJWT
    if (!storageEncodedUnsignedJWT) {
      this.clear()
      return
    }

    // doppelganger synchronization
    const storageDecodedUnsignedJWT = jwtDecode<JWT>(storageEncodedUnsignedJWT)
    if (storageDecodedUnsignedJWT.user?.dg !== this.decodedUnsignedJWT?.user?.dg) {
      this.blockingBearerRefresh()
    }
  }

  hasUsableBearer() {
    return (
      !this.isBearerRefreshActive &&
      !!this.encodedSignedJWT &&
      !!this.decodedSignedJWT &&
      !hasTimestampExpired(this.decodedSignedJWT.exp)
    )
  }

  /**
   * Get the current JWT token.
   *
   * Should only be needed during requests, effectively
   * only the apollo middleware, to pass for authenticating.
   * @return {string|undefined} The JWT token
   */
  bearer() {
    const callback = (
      resolve: (jwt: string | undefined) => void,
      reject: (error: GraphQLFormattedError) => void
    ) => {
      // If we have a session but it's expired, force it to refresh
      // before issuing any further requests.
      if (!this.hasUsableBearer()) {
        this.blockingBearerRefresh()
      }

      this.bearerFetcher.promise.then(() => {
        resolve(this.encodedSignedJWT)
      }, reject)
    }

    return new Promise<string | undefined>(callback.bind(this))
  }

  /**
   * Set the JWT bearer token.
   *
   * Use when receiving a new bearer from the server on sign in or other updates. Will only trigger
   * an update of the JWT and session if the JWT has changed from the current JWT.
   *
   * @param {GraphqlSession} session GraphqlSession with bearer token returned from server
   * @param {string} notifyChange Emit update event if JWT changed
   *
   */
  async onBearerChange(session: GraphqlSession, skipNotifyChange = false) {
    const { decodedUnsignedJWT: oldDecodedUnsignedJWT } = this
    const hadPreviousJWT = !!oldDecodedUnsignedJWT

    // async operation to unzip the token and decode it
    await this.setSession(session)

    const { decodedUnsignedJWT } = this
    const justLoggedIn = !!decodedUnsignedJWT && !hadPreviousJWT

    this.bearerRefreshed()
    if (skipNotifyChange) return

    if (justLoggedIn) this.emit(Session.JWT_CREATE_EVENT_NAME)

    // Only emit a change if session did in fact change
    if (
      !justLoggedIn &&
      (!oldDecodedUnsignedJWT ||
        !session.bearer ||
        !objectHelper.deepEqual(decodedUnsignedJWT, oldDecodedUnsignedJWT))
    ) {
      this.emit(Session.JWT_UPDATE_EVENT_NAME)
    }
  }

  /**
   * Perform a blocking bearer token refresh update.
   * @param skipNotifyTokenUpdate
   */
  blockingBearerRefresh(skipNotifyTokenUpdate = false) {
    if (!this.isBearerRefreshActive) {
      this.emit(BearerRefreshManager.TOKEN_BLOCKING_STARTED_EVENT_NAME, skipNotifyTokenUpdate)
    }
    this.isBearerRefreshActive = true
  }

  /**
   * Perform a mutation version update
   */
  mutationVersionUpdate() {
    this.emit(Session.JWT_MUTATION_VERSION_NAME)
  }

  /**
   * Perform a non blocking bearer token refresh if the session is close to expiring.
   */
  nonBlockBearerRefreshIfNeeded = () => {
    if (!this.decodedUnsignedJWT) return

    // If the refresh-token is expired, automatically log the session out
    if (this.decodedUnsignedJWT.rfxp && hasTimestampExpired(this.decodedUnsignedJWT.rfxp)) {
      this.clear({ code: GRPCErrorCode.Unauthenticated })
      return
    }

    const expLessIssued = this.decodedUnsignedJWT.exp - this.decodedUnsignedJWT.iat
    const unixNow = Date.now() / 1000
    if (unixNow >= this.decodedUnsignedJWT.exp - expLessIssued / 5) {
      this.emit(BearerRefreshManager.TOKEN_NON_BLOCKING_STARTED_EVENT_NAME)
    }
  }

  /**
   * Notify any listeners that the bearer refresh is done.
   */
  bearerRefreshSkipped() {
    this.isBearerRefreshActive = false
    this.emit(BearerRefreshManager.TOKEN_REFRESHED_EVENT_NAME)
  }

  /**
   * Notify any listeners that the bearer token has been refreshed.
   */
  bearerRefreshed() {
    this.isBearerRefreshActive = false
    this.emit(BearerRefreshManager.TOKEN_REFRESHED_EVENT_NAME)
  }

  /**
   * Notify there has been an error when fetching the bearer refresh token.
   * @param error
   */
  bearerFetchError = (error: GraphQLFormattedError) => {
    this.isBearerRefreshActive = false
    this.emit(BearerRefreshManager.FETCH_ERROR_EVENT_NAME, error)
  }

  /*
    INTERNAL
  */

  /**
   * Get the user's preference. Preferences are stored in local storage and keyed
   * off of the user's id. Anything stored here will persist logouts.
   *
   * NOTE: Since this information will persist logouts and be stored in the clear,
   * do not put any sensitive information here.
   *
   * @param {string} preferenceKey Key of the user preference to get back
   * @return {any} The contents stored at the key for the current user
   */
  getUserPreference(preferenceKey: string) {
    return this.getUserLocalStorageValue(Session.PREFERENCES_STORAGE_NAMESPACE, preferenceKey)
  }

  /**
   * Get a shared/global preference. Anything stored here will persist logouts.
   *
   * NOTE: Since this information will persist logouts and be stored in the clear,
   * do not put any sensitive information here.
   *
   * @param {string} preferenceKey Key of the global preference to get back
   * @return {any} The contents stored at the key
   */
  getGlobalPreference(preferenceKey: string) {
    return this.getGlobalLocalStorageValue(Session.PREFERENCES_STORAGE_NAMESPACE, preferenceKey)
  }

  /**
   * Get a boolean value for a user's preference.
   *
   * @param {string} preferenceKey Key of the user preference to get back
   * @param {boolean} defaultValue If the value is not set yet, what should it default to.
   * @return {boolean}
   */
  getBooleanUserPreference(preferenceKey: string, defaultValue = false): boolean {
    const value = this.getUserPreference(preferenceKey)
    return value === undefined ? defaultValue : value === true || value === "true"
  }

  /**
   * Set the user's preference. Preferences are stored in local storage and keyed
   * off of the user's id. Anything stored here will persist logouts.
   *
   * NOTE: Since this information will persist logouts and be stored in the clear,
   * do not put any sensitive information here.
   *
   * @param {string} preferenceKey Key of the user preference to set
   * @param {any} preferenceValue Value of the user preference to set
   * @return {void}
   */
  setUserPreference(preferenceKey: string, preferenceValue: unknown) {
    this.setUserLocalStorageValue(
      Session.PREFERENCES_STORAGE_NAMESPACE,
      preferenceKey,
      preferenceValue
    )
  }

  /**
   * Set a global preference. Preferences are stored in local storage.
   * Anything stored here will persist logouts.
   *
   * NOTE: Since this information will persist logouts and be stored in the clear,
   * do not put any sensitive information here.
   *
   * @param {string} preferenceKey Key of the global preference to set
   * @param {any} preferenceValue Value of the global preference to set
   * @return {void}
   */
  setGlobalPreference(preferenceKey: string, preferenceValue: unknown) {
    this.setGlobalLocalStorageValue(
      Session.PREFERENCES_STORAGE_NAMESPACE,
      preferenceKey,
      preferenceValue
    )
  }

  /**
   * Delete the user's preference. Preferences are stored in local storage and keyed
   * off of the user's id.
   *
   * @param {string} preferenceKey Key of the user preference to remove
   * @return {void}
   */
  deleteUserPreference(preferenceKey: string) {
    // Noop if there is not user
    if (!this.user?.id) return

    this.deleteLocalStorageValue(this.user.id, Session.PREFERENCES_STORAGE_NAMESPACE, preferenceKey)
  }

  /**
   * Delete the global preference. Preferences are stored in local storage.
   *
   * @param {string} preferenceKey Key of the global preference to remove
   * @return {void}
   */
  deleteGlobalPreference(preferenceKey: string) {
    this.deleteLocalStorageValue(
      Session.GLOBAL_STORAGE_NAMESPACE,
      Session.PREFERENCES_STORAGE_NAMESPACE,
      preferenceKey
    )
  }

  /**
   * Clear all the user's preferences. Preferences are stored in local storage and keyed
   * off of the user's id.
   *
   * @return {void}
   */
  clearUserPreferences() {
    this.clearUserLocalStorageValues(Session.PREFERENCES_STORAGE_NAMESPACE)
  }

  /**
   * At times the source code is so far out of date with the latest version that we need to do a
   * window refresh to get the latest. This method will emit an event so app logic can be triggered
   * to warn the user or perform the page refresh.
   */
  appRefreshRequired = () => {
    this.emit(Session.PAGE_REFRESH_REQUIRED_EVENT_NAME)
  }

  /**
   * Sign out of the current session. Effectively destroys the JWT token in local this.storage.
   * Emits a logout event to trigger any functionality based on that change, i.e. redirecting
   * user to login page.
   *
   * @param state {@link SessionLogoutState} A message and logout type to give details
   * on the reason for the logout. For example, if being logged out due to a session
   * expiration, setting that on logout allows for the message to be store in state
   * to be displayed in the UI at a later point.
   * @return {void}
   */
  async clear(state?: SessionLogoutState) {
    this.storage.session.clear()
    await this.setSession(undefined).then(() => this.emit(Session.JWT_DELETE_EVENT_NAME, state))
  }

  logoutDoppelganger() {
    this.clearUserPreferences()
    this.storage.session.clear()
    this.doppelganger = undefined
  }

  getObjectToken(documentId: string) {
    return this.objectTokens.getToken(documentId)
  }

  saveObjectToken(encodedSignedToken: string) {
    return this.objectTokens.saveToken(encodedSignedToken)
  }

  removeObjectToken(documentId: string) {
    return this.objectTokens.removeToken(documentId)
  }

  /**
   * Get the user's local storage value for a provided namespace.
   *
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of the value to get back
   * @return {any} The contents stored at the key for the current user
   */
  protected getUserLocalStorageValue(namespace: string, key: string) {
    // Only get if we have a user since we are going to key off of user id
    if (!this.user?.id) return
    return this.getLocalStorageValue(this.user.id, namespace, key)
  }

  /**
   * Get the global local storage value for a provided namespace.
   *
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of the value to get back
   * @return {any} The contents stored at the key for the current user
   */
  protected getGlobalLocalStorageValue(namespace: string, key: string) {
    return this.getLocalStorageValue(Session.GLOBAL_STORAGE_NAMESPACE, namespace, key)
  }

  /**
   * Get the local storage value for a provided namespace.
   *
   * @param {string} context context is the user id or global key
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of the value to get back
   * @return {any} The contents stored at the key for the current user
   */
  private getLocalStorageValue(context: string, namespace: string, key: string) {
    if (!context) return
    try {
      // Get all users values from local storage
      const allValues = this.storage.local.getItem(namespace)

      // Parse the values or default to empty object if there are none
      const allValuesObject = JSON.parse(allValues || "{}")

      // Get the values for the user or global context
      const userValues = allValuesObject[context] || {}

      // If there are no values, return undefined
      if (!userValues) return

      // Otherwise, return whatever values are set for that key
      return userValues[key]
    } catch (error) {
      TrackJS?.console.error(`Error getting ${context} - ${namespace} key: ${key}`, error)
    }
  }

  /**
   * Set the user's local storage value for the provided namespace and key.
   *
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of what is to be set
   * @param {any} value Value of what is to be set
   * @return {void}
   */
  protected setUserLocalStorageValue(namespace: string, key: string, value: unknown) {
    if (!this.user?.id) return
    return this.setLocalStorageValue(this.user.id, namespace, key, value)
  }

  /**
   * Set the global local storage value for the provided namespace and key.
   *
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of what is to be set
   * @param {any} value Value of what is to be set
   * @return {void}
   */
  protected setGlobalLocalStorageValue(namespace: string, key: string, value: unknown) {
    return this.setLocalStorageValue(Session.GLOBAL_STORAGE_NAMESPACE, namespace, key, value)
  }

  /**
   * Set the local storage value for the provided namespace and key.
   *
   * @param {string} context context is the user id or global key
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of what is to be set
   * @param {any} value Value of what is to be set
   * @return {void}
   */
  protected setLocalStorageValue(context: string, namespace: string, key: string, value: unknown) {
    if (!context || key === "" || value === "") return

    // Get all values from local storage
    const allValues = this.storage.local.getItem(namespace)

    try {
      // Parse the values and default to empty object
      const allObjects = JSON.parse(allValues || "{}")

      // Set an empty object if the context does not exist in values
      if (!allObjects[context]) allObjects[context] = {}

      // Set the value
      allObjects[context][key] = value

      // Set all values back to local storage as string
      this.storage.local.setItem(namespace, JSON.stringify(allObjects))
    } catch (error) {
      TrackJS?.console.error(
        `Error setting ${context} - ${namespace} key: ${key} value: ${value}`,
        error
      )
    }
  }

  /**
   * Delete the user's localstorage value for a the provided namespace
   *
   * @param {string} context context is the user id or global key
   * @param {string} namespace Namespace in localstorage for the user value
   * @param {string} key Key of the value to remove
   * @return {void}
   */
  protected deleteLocalStorageValue(context: string, namespace: string, key: string) {
    // Noop if there is not user
    if (!context) return

    // Get all users values stored
    const allUsersValues = this.storage.local.getItem(namespace)
    try {
      // Parse them to an object
      const allUsersObject = JSON.parse(allUsersValues || "{}")

      // If that context does not have any values, noop
      if (!allUsersObject[context]) return

      // delete the values passed if the key has stored
      delete allUsersObject[context][key]

      // Set all values back to local storage as string
      this.storage.local.setItem(namespace, JSON.stringify(allUsersObject))
    } catch (error) {
      TrackJS?.console.error(`Error deleting ${context} - ${namespace} key: ${key}`, error)
    }
  }

  /**
   * Clear all of the user's localstorage values for a provide namespace.
   *
   * @param {string} namespace Namespace in localstorage for the user value
   * @return {void}
   */
  protected clearUserLocalStorageValues(namespace: string) {
    // Noop if there is not user
    if (!this.user || !this.user.id) return

    // Get all values
    const allUsersValues = this.storage.local.getItem(namespace)
    try {
      // Parse them to an object
      const allUsersObject = JSON.parse(allUsersValues || "{}")

      // If that user does not have any values, noop
      if (!allUsersObject[this.user.id]) return

      // delete all values for the user
      delete allUsersObject[this.user.id]

      // Set all values back to local storage as string
      this.storage.local.setItem(namespace, JSON.stringify(allUsersObject))
    } catch (error) {
      TrackJS?.console.error(`Error clearing ${namespace} for user with id: ${this.user.id}`, error)
    }
  }

  /**
   * Decodes the JWT into its stored data then check if its expired.
   *
   * @return {void}
   */
  protected decodeSession() {
    const session = this.decodedUnsignedJWT

    if (session?.rfxp && hasTimestampExpired(session.rfxp)) {
      this.clear({ code: GRPCErrorCode.Unauthenticated })
      return
    }

    this.decodeSessionUser(session)

    // Set the trackJs user id to the user's id if we have it. Otherwise set to an empty string
    // which should fallback to OS + Browser + IP
    window.TrackJS?.configure({ userId: this.user.id || "" })
    window.TrackJS?.addMetadata("Doppelganger Id", this.decodedUnsignedJWT?.user?.dg || "")
  }

  /**
   * Set the user from the JWT session.
   *
   * @param {JWT | undefined} session jwt session object
   */
  protected decodeSessionUser(session?: JWT) {
    // Set the user from the session
    const rawSessionUser = (session && session.user) || undefined
    this.user = new SessionUser(rawSessionUser)

    const isDoppelganger = !!this.decodedUnsignedJWT?.user?.dg
    if (isDoppelganger) {
      this.doppelganger = new DoppelgangerAccessLevel()
    }
  }

  /**
   * Setter for JWT. Private since only internal methods
   * should need to set this token.
   */
  protected async setSession(session: GraphqlSession | undefined) {
    if (!session) {
      this.encodedSignedJWT = undefined
      this.decodedSignedJWT = undefined
      this.decodedUnsignedJWT = undefined
      this.storage.local.removeItem(Session.JWT_STORAGE_NAMESPACE)
      this.decodeSession()
      return Promise.resolve()
    }

    const { encodedSignedJWT, decodedSignedJWT, encodedTruncatedJWT, decodedUnsignedJWT } =
      await jwtParse<JWT>(session.bearer)

    this.encodedSignedJWT = encodedSignedJWT
    this.decodedSignedJWT = decodedSignedJWT
    this.decodedUnsignedJWT = decodedUnsignedJWT
    this.storage.local.setItem(Session.JWT_STORAGE_NAMESPACE, encodedTruncatedJWT)
    this.decodeSession()
  }
}

/**
 * If expires at is less than the current time (meaning `a time in the past`),
 * then it's considered invalid
 *
 * @param {number} expiresAt unix timestamp
 * @return {boolean} If timestamp is before now
 */
function hasTimestampExpired(expiresAt: number) {
  return moment.unix(expiresAt).isBefore()
}
